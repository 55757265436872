<template>
  <div class="physicalPDF">
    <div class="physicalRise">
      <div class="hospitalName">{{ orgName }}</div>
      <div class="physicalTime">报告日期：{{ reportDate }}</div>
    </div>
    <div class="name">姓名：{{ name }}</div>
    <div class="summary">总结：{{ summary }}</div>
    <div class="subject">建议：{{ subject }}</div>
    <div class="table">
      <div class="tableTitle">
        <div class="titleName">项目名称</div>
        <div class="titleRes">结果</div>
        <div class="titleRenge">参考值</div>
      </div>
      <div class="tableContent" v-for="(item, index) in list" :key="index">
        <div class="physicalItem">{{ item.project }}</div>
        <div
          class="pdfInfo"
          v-for="(itemOne, indexOne) in item.childrenList"
          :key="indexOne"
        >
          <div class="contentName">{{ itemOne.item_name }}</div>
          <div class="contentRes">
            {{ itemOne.value }}
          </div>
          <div class="contentRenge">{{ itemOne.range }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportPdfInfo } from '@/network/service'
export default {
  name: 'physicalPDF',
  data() {
    return {
      name: '',
      subject: '',
      summary: '',
      orgName: '',
      reportDate: '',
      list: [],
    }
  },
  components: {},
  created() {
    this.getReportPdfUrl()
  },
  methods: {
    async getReportPdfUrl() {
      let res = await reportPdfInfo({
        id_no: this.$Base64.decode(this.$store.state.cardList[0].idNo),
        report_no: this.$route.query.reportNo,
        name: this.$store.state.cardList[0].name,
      })
      if (res.code == 0) {
        let {
          name,
          subject,
          summary,
          detail,
          org_name,
          report_date,
        } = res.data.root.body
        this.name = name
        this.subject = subject
        this.summary = summary
        this.orgName = org_name
        this.reportDate = report_date
        let obj = {}
        let arr = detail.reduce((cur, next) => {
          obj[next.project] ? '' : (obj[next.project] = true && cur.push(next))
          return cur
        }, [])
        arr.forEach(el => {
          el.childrenList = []
          detail.forEach(item => {
            if (el.project == item.project) {
              el.childrenList = [...el.childrenList, ...item.sub_detail]
            }
          })
        })
        this.list = arr
      } else {
        this.$toast(res.msg)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.physicalPDF {
  width: 100vw;
  height: 100vh;
  padding: 0rem 1rem 1rem 1rem;
  box-sizing: border-box;
  overflow: auto;
  .physicalRise {
    padding: 0.5rem 0 0.3rem 0rem;
    box-sizing: border-box;
    line-height: 1.3rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .hospitalName {
      font-weight: bold;
    }
    .physicalTime {
      font-size: 0.9rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .name {
    margin: 0.7rem 0 0.3rem 0;
  }
  .summary {
    margin-bottom: 0.3rem;
  }
  .subject {
    margin-bottom: 0.7rem;
  }
  .table {
    border: 2px solid #efefef;
    .tableTitle {
      display: flex;
      line-height: 2rem;
      padding: 0 0.5rem;
      box-sizing: border-box;
      .titleName {
        flex: 0.5;
      }
      .titleRes {
        padding-left: 0.3rem;
        box-sizing: border-box;
        flex: 0.23;
      }
      .titleRenge {
        flex: 0.27;
      }
    }
    .tableContent {
      line-height: 1.3rem;
      background-color: #f3f8fe;
      .physicalItem {
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        line-height: 1.5rem;
        background-color: #89a1c0;
      }
      .pdfInfo {
        display: flex;
        padding: 0.3rem 0.5rem;
        box-sizing: border-box;
        .contentName {
          flex: 0.5;
        }
        .contentRes {
          padding-left: 0.3rem;
          box-sizing: border-box;
          flex: 0.23;
        }
        .contentRenge {
          flex: 0.27;
        }
        .downarrow,
        .uparrow {
          width: 1rem;
          height: 1.3rem;
        }
      }
    }
  }
}
</style>
